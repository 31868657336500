export default {
  category: 'book',
  name: 'Outlive',
  author: 'Peter Attia',
  date: '4/11/25',
  path: '/notes/outlive',
  photo: 'https://twos.s3.amazonaws.com/parkerklein/parker-klein-outlive-peter-attia.jpg',
  tags: ['Health', 'Diet'],
  time: 82,
  rating: 8.2,
  review: '',
  link: 'https://www.amazon.com/Outlive-Longevity-Peter-Attia-MD/dp/0593236599',
  mediumLink: '',
  patreonLink: '',
  // podcastLink: 'https://anchor.fm/parkerklein/episodes/',
  // podcastEmbed: 'https://anchor.fm/parkerklein/embed/episodes/',
  // youtubeLink: 'https://youtu.be/',
  twosLink: 'https://www.twosapp.com/67cee23e7505326ac21deccf',
  // youtubeEmbed: 'https://www.youtube.com/embed/',
  releaseDate: '',
};
